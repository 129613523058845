<template>
  <div v-if="isshowgrid">
    <va-card title="Advertisement Slot List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
          	<va-select
              v-if = "isCreate"
              label="Slot Time*"
              placeholder="Select Time Slot"
              v-model="slot_time"
              textBy="time"
              :options="time"
              :error="!!slotTimeErrors.length"
              :error-messages="slotTimeErrors"
              noClear
            />
            <va-input
              v-if="isUpdate"
              v-model="slot_time"
              type="text"
              min="1"
              label="slot time*"
              placeholder="Enter Duration in seconds"
              :error="!!durationErrors.length"
              :error-messages="durationErrors"
              :disabled="true"
            />
            <va-input
              v-model="duration"
              type="number"
              min="1"
              label="duration* (Max value 20)"
              placeholder="Enter Duration in seconds"
              @input="if(Number(duration) > 20) duration = '';"
              :error="!!durationErrors.length"
              :error-messages="durationErrors"
            />
            <va-input
              v-model="repetitive_count"
              type="number"
              min="1"
              label="repetitive count* (Max value 6)"
              @input="if(Number(repetitive_count) > 6) repetitive_count = '';"
              placeholder="Enter Repetitive Count"
              :error="!!repetitiveCountErrors.length"
              :error-messages="repetitiveCountErrors"
            />
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createAdSlot()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateAdSlot()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'advertisement_slot',
  created () {
    this.getAdvertisementSlotList() 
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      amount: 0,
      repetitive_count: '',
      duration: '',
      slot_time:[],
      title: '',
      ad_slot_list: [],
      durationErrors: [],
      time : [],
      repetitiveCountErrors: [],
      slotTimeErrors: [],
    }
  },
  computed: {
    formReady () {
      return !this.durationErrors.length && !this.repetitiveCountErrors.length && !this.slotTimeErrors.length
    },
    fields () {
      return [{
          name: 'sno',
          title: 'S.NO',
          width: '6%',
      },{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'slot_time',
        title: 'Slot Time',
      },
      {
        name: 'duration',
        title: 'Duration',
      },
      {
        name: 'repetitive_count',
        title: 'Repetitive Count',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.ad_slot_list)
    },
  },
  methods: {
    getAdvertisementSlotList () {
      this.$http.get(config.menu.host + '/advertisement_slot').then(response => {
        let index = 0
        this.ad_slot_list = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
        this.$http.get(config.menu.host + '/advertisement_slot/timings').then(time => {
	        this.time = (time.body.length > 0) ? time.body : []
	      })
      })
    },
    createAdSlot () {
      this.durationErrors = this.duration ? [] : ['Duration is required']
      this.repetitiveCountErrors = this.repetitive_count ? [] : ['Repetitive Count is required']
      this.slotTimeErrors = this.slot_time ? [] : ['Slot Time is required']
      if (!this.formReady) {
        return
      }
      var payload = {
      	slot_time : this.slot_time.time,
        duration: Number(this.duration),
        repetitive_count: Number(this.repetitive_count),
        amount : 0
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/advertisement_slot', payload).then(responseData => {
      	loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
      	loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateAdSlot () {
      var payload = {
        slot_time: this.slot_time,
        duration: Number(this.duration),
        repetitive_count: Number(this.repetitive_count),
        ad_slot_id: this.ad_slot_id,
        amount : 0
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/advertisement_slot/' + this.ad_slot_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
	add () {
		this.title = 'Create Advertisement Slot'
		this.slot_time = []
		this.duration = ''
		this.repetitive_count=''
		this.amount = 0
		this.genresErrors = []
		this.priorityErrors = []
		this.isshowForm = true
		this.isshowgrid = false
		this.isUpdate = false
		this.isCreate = true
	},
    edit (row) {
      this.title = 'Update Advertisement Slot'
      this.slot_time = row.slot_time
      this.ad_slot_id = row.ad_slot_id
      this.duration = row.duration
      this.amount 	= 0
      this.repetitive_count = row.repetitive_count
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
	list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAdvertisementSlotList()
    },
    search: debounce(function (term) {
    	this.term = term
    }, 400),
  },
}
</script>
